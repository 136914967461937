import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("removeUnderscores", function(value) {
  if (!value) return "";
  value = value.toString();
  value = value.split("_").join(" ");
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("uppercase", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

//filterDate
Vue.filter("date", (value) => {
  return dayjs(value).format("DD-MM-YYYY hh:mm a");
});
