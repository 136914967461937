import axios from "axios";

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}/api/v1`;

export const createAxios = (token) => {
  return axios.create({
    baseURL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
};

export default createAxios;
