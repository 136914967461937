<template>
  <span @click="goTo"><slot></slot></span>
</template>

<script>
export default {
  name: "SidebarLink",
  props: {
    to: String,
    type: String
  },
  methods: {
    goTo: function() {
      let rolUser = this.$store.getters["user/showRolUser"];
      if (this.to === "/emails/ner") {
        if (rolUser.mode !== "admin") {
          localStorage.setItem("isEmailAssign", true);
          this.$router.push({ name: "details", params: { type: "ner" } });
        } else {
          localStorage.setItem("isEmailAssign", false);
          if (this.$route.path === this.to)
            this.$router.go({ name: this.to, params: { type: this.type } });
          else
            this.$router.push({ path: this.to, params: { type: this.type } });
        }
      } else {
        localStorage.setItem("isEmailAssign", false);
        if (this.$route.path === this.to)
          this.$router.go({ name: this.to, params: { type: this.type } });
        else this.$router.push({ path: this.to, params: { type: this.type } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
span {
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
</style>
