import config from "@/utils/config.js";
import * as api from "@/api/services";

export const updateEmailList = (store) => {
  store.subscribe(async ({ type }, state) => {
    if (
      (type === "emailList/setEmails" || type === "user/setUserStatus") &&
      state.status === 5 &&
      state.user.userStatus === "active"
    ) {
      try {
        const { data: emailCount } = await api.countEmails(state.status);
        if (emailCount <= config.minEmails) {
          store.dispatch("emailList/assignEmails", config.assignLimit);
        }
      } catch (err) {
        console.error(err.message);
      }
    }
  });
};
