import axios from "axios";
export default {
  namespaced: true,
  state: {
    allUsers: []
  },
  getters: {},
  mutations: {
    setAllUsers(state, allUsers) {
      state.allUsers = allUsers;
    }
  },
  actions: {
    async getAllUsers({ commit }, params) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/v1/users`,
          {
            params: params,
            headers: headers
          }
        );
        if (response.status === 200) {
          commit("setAllUsers", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log(err);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getOrganizationOptions(_) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/v1/organizations`,
          {
            headers: headers
          }
        );
        return response.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log(err);
        }
      }
    },
    async createNewUser(_, body) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL + `/api/v1/users`,
          body,
          {
            headers: headers
          }
        );
        return response;
      } catch (err) {
        if (err.response.status === 401) {
          console.log(err);
        }
      }
    },
    async deleteUser(_, userId) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.delete(
          process.env.VUE_APP_BASE_URL + `/api/v1/users/${userId}`,
          {
            headers: headers
          }
        );
        return response;
      } catch (err) {
        if (err.response.status === 422) {
          console.log(err);
        }
      }
    },
    async editUser(_, body) {
      let userId = body.userId;
      delete body.userId;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BASE_URL + `/api/v1/users/${userId}`,
          body,
          {
            headers: headers
          }
        );
        return response;
      } catch (err) {
        if (err.response.status === 422) {
          console.log(err);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getTotalUsers(_) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/v1/users/count`,
          {
            headers: headers
          }
        );
        return response.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log(err);
        }
      }
    }
  }
};
