"use strict";

import * as api from "@/api/services";
import axios from "axios";
import Swal from "sweetalert2";
import io from "socket.io-client";

export default {
  namespaced: true,
  state: {
    disabledAccesorials: "",
    clientId: null,
    cannedId: null,
    selectedEmail: {
      id: "",
      html: "",
      message: "",
      date: "",
      subject: "",
      probability: {},
      trips: [],
      valid: true,
      emailContent: null,
      countQuotes: null,
      tagsEmail: null
    },
    statusEmailAssign: null,
    mapCountry: {
      country: null,
      target: null
    },
    notificationsSocket: null,
    infoGeneralUsers: null,
    infoGeneralUsersQuotes: null,
    generalInfoUsers: null,
    countPeticionControl: 0,
    newTripButton: false,
    htmlFinished: 0,
    getAllAccessorials: [],
    flagAddCommodity: 0,
    currentTripVuex: null,
    attachments: [],
    deleteEmptyRow: []
  },
  getters: {
    getClientId: (state) => state.clientId,
    getCannedId: (state) => state.cannedId,
    selectedEmail: (state) => state.selectedEmail,
    getTrip: (state) => (id) => {
      return state.selectedEmail.trips.find((trip) => trip.id === id);
    },
    pickupRequestByTrip: (state, getters) => (id) => {
      return getters.getTrip(id).pickup_request;
    },
    deliveryRequestByTrip: (state, getters) => (id) => {
      return getters.getTrip(id).delivery_request;
    },
    dispatchRequestByTRip: (state, getters) => (id) => {
      return getters.getTrip(id).dispatch_request;
    },
    showIsEmailAssing(state) {
      return state.isEmailAssing;
    },
    showStatusEmailAssign(state) {
      return state.statusEmailAssign;
    }
  },
  actions: {
    async getLinearFeet(_, data) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/operations/v3/linerar-feet/?container=${data.container}`,
          data.body,
          { headers: headers }
        );
        return response.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("tokenExpired");
        }
      }
    },
    deleteEmptyRows({ state, commit }, tripId) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === tripId) {
          if (trip.commodity.length > 0) {
            trip.commodity.forEach((row, index) => {
              if (
                row.hu_count == "" &&
                row.weight == "" &&
                row.dimensions == ""
              ) {
                commit("setDeleteEmptyRow", index);
              }
            });
          }
        }
      });
    },
    getEmailById: async ({ commit, dispatch, state }, { id, status }) => {
      // let email = rootState.emailList.emails.find(
      //   (email) => email.custom_id === id
      // );
      // if (!email) {
      return api.getEmailById(id, status).then(async (response) => {
        const fullEmail = await api.mapEmails([response.data]);
        const processedEmail = await dispatch(
          "emailList/preprocessEmails",
          fullEmail,
          { root: true }
        );
        commit("selectEmail", processedEmail[0]);
        state.attachments = processedEmail[0].attachments;
      });
      // } else commit("selectEmail", email);
    },
    async getEmailAssing({ commit }) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/v1/quotes/assign`,
          { headers: headers }
        );
        if (response.status === 200) {
          commit("setFileAssing", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("tokenExpired");
        } else if (err.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `There are no more quotes`
          });
        } else if (err.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Change your status to activate if you want to join the queue`
          });
        }
      }
    },
    async getAccessorials({ commit }, params) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/v1/accessorials`,
          {
            params: params,
            headers: headers
          }
        );
        if (response.status === 200) {
          commit("setGetAccessorials", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("tokenExpired");
        }
      }
    },
    async getCountQuotes({ commit }, data) {
      let params = {};
      params.user_id = data.user_id;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/quotes/count${data.status_list}`,
          {
            headers: headers,
            params: params
          }
        );
        if (response.status === 200) {
          commit("setCountQuotes", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("tokenExpired");
        } else if (err.response.status !== 200) {
          console.log(err);
        }
      }
    },
    async sendToLtl(_, quoteId) {
      let body = {};
      body.status = 5;
      body.user_id = null;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BASE_URL + `/api/v1/quotes/${quoteId}`,
          body,
          { headers: headers }
        );
        return response;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("tokenExpired");
        } else if (err.response.status !== 200) {
          console.log(err);
        }
      }
    },
    async getSendToAutoquoter(_, payload) {
      let body = {};
      body.hauls = payload.hauls;
      body.incomplete = payload.incomplete;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/quotes/${payload.customId}/autoquoter`,
          body,
          { headers: headers }
        );
        return response;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("tokenExpired");
        } else if (err.response.status === 200 || err.response.status === 404) {
          console.log(err);
        }
      }
    },
    toNonLtl(ctx, custom_id) {
      const patch = { status: 10 };
      return api.patchEmail(custom_id, patch);
    },
    addTrip({ commit, dispatch, state }, customId) {
      return api.addTrip(customId).then(function({ data }) {
        if (state.selectedEmail.trips.length > 0) {
          let infoLastTrip = {
            ...state.selectedEmail.trips[state.selectedEmail.trips.length - 1]
          };
          let pickupRequest = { ...infoLastTrip.pickup_request };
          let deliveryRequest = { ...infoLastTrip.delivery_request };
          data.accessorials = [];
          data.accessorials_from = [];
          data.accessorials_to = [];
          for (let i in infoLastTrip.accessorials) {
            data.accessorials.push(infoLastTrip.accessorials[i]);
          }
          for (let i in infoLastTrip.accessorials_from) {
            data.accessorials_from.push(infoLastTrip.accessorials_from[i]);
          }
          for (let i in infoLastTrip.accessorials_to) {
            data.accessorials_to.push(infoLastTrip.accessorials_to[i]);
          }
          data.city_from = infoLastTrip.city_from;
          data.city_to = infoLastTrip.city_to;
          data.commercial_value = infoLastTrip.commercial_value;
          data.delivery_request = deliveryRequest;
          data.dispatch_request = infoLastTrip.dispatch_request;
          data.linear_feet = infoLastTrip.linear_feet;
          data.pickup_request = pickupRequest;
          data.tracking_request = infoLastTrip.tracking_request;
          data.zip_from = infoLastTrip.zip_from;
          data.zip_to = infoLastTrip.zip_to;
          data.density_class = infoLastTrip.density_class;
          data.requoted = infoLastTrip.requoted;
          data.insurance_required = infoLastTrip.insurance_required;
          data.commodity = infoLastTrip.commodity;
          commit("addTrip", data);
          for (let i in infoLastTrip.commodity) {
            let infoNewRow = {};
            infoNewRow.id = data.id;
            infoNewRow.isNewTrip = true;
            infoNewRow.index = i;
            dispatch("addCommodity", infoNewRow);
          }
        } else {
          commit("addTrip", data);
          let infoNewRow = {};
          infoNewRow.id = data.id;
          infoNewRow.isNewTrip = false;
          dispatch("addCommodity", infoNewRow);
        }
      });
    },
    deleteTrip: ({ commit }, id) => {
      return new Promise(function(resolve, reject) {
        try {
          if (typeof id !== "undefined") {
            const res = api.deleteTrip(id);
            resolve(res);
          }
          resolve();
        } catch (err) {
          reject(err);
        } finally {
          commit("deleteTrip", id);
        }
      });
    },
    addCommodity({ commit }, infoNewRow) {
      let tripId = infoNewRow.id;
      return api.addCommodity(tripId).then(function({ data }) {
        commit("addRowToCommodity", {
          tripId,
          commodityId: data.id,
          isNewTrip: infoNewRow.isNewTrip,
          index: infoNewRow.index,
          pilotAcc: infoNewRow.pilotAcc
        });
      });
    },
    deleteCommodity: ({ commit, getters }, { tripId, commodityIndex }) => {
      return new Promise(function(resolve, reject) {
        const trip = getters.getTrip(tripId);
        const commodityId = trip.commodity[commodityIndex].id;
        try {
          if (typeof commodityId !== "undefined") {
            const res = api.deleteCommodity(commodityId);
            resolve(res);
          }
          resolve();
        } catch (err) {
          reject(err);
        } finally {
          commit("deleteCommodity", { tripId, commodityId });
        }
      });
    },
    notificationsSocket({ commit, state }) {
      let token = localStorage.getItem("token");
      const baseDomain = process.env.VUE_APP_BASE_URL;
      const notificationRoute = baseDomain + "/" + "notifications";
      state.notificationsSocket = io(notificationRoute, {
        path: "/sio/socket.io/",
        transports: ["websocket"],
        query: {
          Authorization: `Bearer ${token}`
        }
      });
      commit("setNotificationsSocketListener");
    },
    generalInfoUsersSocket({ commit, state }) {
      let token = localStorage.getItem("token");
      const baseDomain = process.env.VUE_APP_BASE_URL;
      const generalUsersRoute = baseDomain + "/" + "admin";
      state.generalInfoUsers = io(generalUsersRoute, {
        path: "/sio/socket.io/",
        transports: ["websocket"],
        query: {
          Authorization: `Bearer ${token}`
        }
      });
      commit("setGeneralInfoUsersListener");
      commit("setGeneralInfoUserQuotesListener");
    }
  },
  mutations: {
    setDeleteEmptyRow(state, indexRow) {
      state.deleteEmptyRow.push(indexRow);
    },
    setCurrentTrip(state, newCurrenTrip) {
      state.currentTripVuex = newCurrenTrip;
    },
    setGetAccessorials(state, accessorials) {
      state.getAllAccessorials = accessorials;
    },
    setNotificationsSocketListener(state) {
      state.notificationsSocket.on("new-quote", function() {
        state.countPeticionControl += 1;
      });
    },
    setGeneralInfoUsersListener(state) {
      state.generalInfoUsers.on("quotes-per-users", function(data) {
        state.infoGeneralUsers = data;
      });
    },
    setGeneralInfoUserQuotesListener(state) {
      state.generalInfoUsers.on("user-quotes", function(data) {
        state.infoGeneralUsersQuotes = data;
      });
    },
    filterGeneralTable(state, data) {
      let startDate = "";
      let endDate = "";
      if (data.startDate !== "" && data.endDate !== "") {
        startDate = `${data.startDate}T10:00:00`;
        endDate = `${data.endDate}T23:00:00`;
      }
      if (
        data.startTime !== "" &&
        data.endTime !== "" &&
        data.startDate !== "" &&
        data.endDate !== ""
      ) {
        let initial_date = data.startDate + " " + `${data.startTime}:00`;
        let final_date = data.endDate + " " + `${data.endTime}:00`;
        let initialDate = new Date(initial_date);
        let finalDate = new Date(final_date);
        startDate = initialDate.toISOString();
        endDate = finalDate.toISOString();
      }
      state.generalInfoUsers.emit("quotes_per_users", {
        initial_date: startDate,
        final_date: endDate,
        status: data.statusOptions,
        username: data.search,
        organization_id:
          data.organization_id !== "" ? data.organization_id : null
      });
    },
    clickGeneralTable(state, data) {
      let startDate = "";
      let endDate = "";

      if (data.startDate !== "" && data.endDate !== "") {
        startDate = `${data.startDate}T10:00:00`;
        endDate = `${data.endDate}T23:00:00`;
      }
      if (
        data.startTime !== "" &&
        data.endTime !== "" &&
        data.startDate !== "" &&
        data.endDate !== ""
      ) {
        let initial_date = data.startDate + " " + `${data.startTime}:00`;
        let final_date = data.endDate + " " + `${data.endTime}:00`;
        let initialDate = new Date(initial_date);
        let finalDate = new Date(final_date);
        startDate = initialDate.toISOString();
        endDate = finalDate.toISOString();
      }
      state.generalInfoUsers.emit("user_quotes", data.id, {
        initial_date: startDate,
        final_date: endDate,
        skip: 0,
        limit: 1000,
        status: data.optionSelected,
        order_by: "-created_at"
      });
    },
    setCloseNotificationSocket(state) {
      state.notificationsSocket.disconnect();
    },
    setMapCountry(state, newMapCountry) {
      state.mapCountry = { ...newMapCountry };
    },
    newTripButton(state, newState) {
      state.newTripButton = newState;
    },
    setCountQuotes(state, newState) {
      state.countQuotes = newState;
    },
    setFileAssing(state, email) {
      state.attachments = email.attachments;
      email.haul.length === 0
        ? (state.newTripButton = true)
        : (state.newTripButton = false);
      state.statusEmailAssign = email.status;
      state.selectedEmail = {
        id: email.custom_id,
        html: email.html_message || "",
        message: email.message || "",
        date: email.created_at || "",
        subject: email.email_subject || "",
        probability: email.model_response.probability || {},
        trips: email.haul || [],
        isValid: typeof email.model_response !== "undefined",
        tagsEmail: email.tag_email
      };
      state.clientId = email.client_id;
      state.cannedId = email.canned_id;
    },
    setEmailContent(state, content) {
      state.selectedEmail.emailContent = content;
    },
    setHtmlFinished(state) {
      state.htmlFinished += 1;
    },
    selectEmail: (state, email) => {
      state.selectedEmail = {
        id: email.custom_id,
        html: email.html_message || "",
        message: email.message || "",
        date: email.created_at || "",
        subject: email.email_subject || "",
        probability: email.model_response.probability || {},
        trips: email.haul || [],
        isValid: typeof email.model_response !== "undefined",
        tagsEmail: email.tag_email
      };
      state.clientId = email.client_id;
    },
    // TRIP MUTATIONS
    addTrip: (state, trip) => {
      state.selectedEmail.trips.push({ ...trip, commodity: [] });
    },
    deleteTrip: (state, id) => {
      state.selectedEmail.trips = state.selectedEmail.trips.filter(
        (trip) => trip.id && trip.id !== id
      );
      if (state.selectedEmail.trips.length === 0) {
        state.newTripButton = true;
      }
    },
    deleteCommodity(state, { tripId, commodityId }) {
      const trip = state.trips.find((trip) => trip.id === tripId);
      const index = trip.commodity.findIndex(
        (commodity) => commodity.id === commodityId
      );
      trip.splice(index, 1);
    },
    setTripBy: (state, { entity, id, value }) => {
      const index = state.selectedEmail.trips.findIndex(
        (trip) => trip.id === id
      );
      state.selectedEmail.trips[index][entity] = value;
    },
    // ACCESSORIALS MUTATIONS
    setAccessorials: (state, { id, category, value }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) {
          trip[category] = value.slice(0);
        }
      });
    },
    addAccessorial: (state, { id, category, acc }) => {
      // let clientId = localStorage.getItem("client_id");
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip[category].push(acc);
      });
      if (
        acc === "flatbed" ||
        acc === "straight truck" ||
        acc === "sprinter van" ||
        acc === "cargo van" ||
        acc === "dedicated ftl"
      ) {
        state.disabledAccesorials = acc;
      }
    },
    removeAccessorial: (state, { id, category, acc }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id)
          trip[category] = trip[category].filter((item) => item !== acc);
      });
    },
    // COMMODITY MUTATIONS
    addRowToCommodity: (
      state,
      { tripId, commodityId, isNewTrip, index, pilotAcc }
    ) => {
      if (pilotAcc !== "" && pilotAcc !== undefined) {
        if (pilotAcc === "flatbed" || pilotAcc === "dedicated ftl") {
          state.selectedEmail.trips.forEach((trip) => {
            if (trip.id === tripId)
              trip.commodity.push({
                id: commodityId,
                hu_count: "24",
                dimensions: "48*40*48",
                weight: "40000",
                contribution: "",
                nmfc_calculated: "",
                nmfc: "",
                description: pilotAcc,
                haul_id: tripId
              });
          });
        } else if (
          pilotAcc === "straight truck" ||
          pilotAcc === "sprinter van" ||
          pilotAcc === "cargo van"
        ) {
          state.selectedEmail.trips.forEach((trip) => {
            if (trip.id === tripId)
              trip.commodity.push({
                id: commodityId,
                hu_count: "12",
                dimensions: "48*40*48",
                weight: "20000",
                contribution: "",
                nmfc_calculated: "",
                nmfc: "",
                description: pilotAcc,
                haul_id: tripId
              });
          });
        }
      } else {
        if (!isNewTrip) {
          state.selectedEmail.trips.forEach((trip) => {
            if (trip.id === tripId)
              trip.commodity.push({
                id: commodityId,
                hu_count: "",
                dimensions: "",
                weight: "",
                contribution: "",
                nmfc_calculated: "",
                nmfc: "",
                description: "",
                haul_id: tripId
              });
          });
        } else {
          let lastTrip =
            state.selectedEmail.trips[state.selectedEmail.trips.length - 2];
          let currentTrip =
            state.selectedEmail.trips[state.selectedEmail.trips.length - 1];
          currentTrip.commodity.push({
            id: commodityId,
            hu_count: lastTrip.commodity[index].hu_count,
            dimensions: lastTrip.commodity[index].dimensions,
            weight: lastTrip.commodity[index].weight,
            contribution: lastTrip.commodity[index].contribution,
            nmfc_calculated: lastTrip.commodity[index].nmfc_calculated,
            nmfc: lastTrip.commodity[index].nmfc,
            description: lastTrip.commodity[index].description,
            haul_id: tripId
          });
        }
      }
      state.flagAddCommodity += 1;
    },
    removeRowFromCommodity: (state, { id, index }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.commodity.splice(index, 1);
      });
    },
    setContributions: (state, { id, contr }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) {
          for (let row in trip.commodity) {
            trip.commodity[row].contribution = contr[row];
          }
        }
      });
    },
    setEntityValue: (state, { id, index, entity, entityValue }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.commodity[index][entity] = entityValue;
      });
    },
    setLinearFeet: (state, { id, lf }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.linear_feet = lf;
      });
    },
    setCommercialValue: (state, { id, value }) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.commercial_value = value;
      });
    },
    setTrackingRequest(state, { id, trackingRequest }) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.tracking_request = trackingRequest;
      });
    },
    setPickupRequest(state, { id, pickupRequest }) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.pickup_request = pickupRequest;
      });
    },
    setDeliveryRequest(state, { id, deliveryRequest }) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.delivery_request = deliveryRequest;
      });
    },
    setDispatchRequest(state, { id, dispatchRequest }) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.dispatch_request = dispatchRequest;
      });
    },
    setRequoted(state, { id, requoted }) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.requoted = requoted;
      });
    },
    setInsuranceRequired(state, { id, insuranceRequired }) {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id) trip.insurance_required = insuranceRequired;
      });
    }
  }
};
