"use strict";

import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async canNotQuote(__, info) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      };
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/quotes/${info.custom_id}/can-not-quote/`,
          info,
          config
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your quote has been sent",
            showConfirmButton: false,
            timer: 2000
          });
          router.push({
            name: "home"
          });
        }
        return response;
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Can't send quote ,please try again`
        });
      }
    }
  }
};
