"use strict";

import Vue from "vue";
import VueRouter from "vue-router";

// Middlewares
import { isAuth } from "@/middlewares/auth";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  hashbang: false,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/LoginPage.vue"),
      props: true
    },
    {
      path: "/",
      name: "home",
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/HomePage.vue")
    },
    {
      path: "/emails/:type",
      name: "emailList",
      meta: {
        requiresAuth: true
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "ner" */ "../views/EmailList.vue")
    },
    {
      path: "/details/:type?/:id?",
      name: "details",
      meta: {
        requiresAuth: true
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "details" */ "../views/EmailDetails.vue")
    },
    {
      path: "/email/:id",
      name: "email",
      meta: {
        requiresAuth: true
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "details" */ "../views/EmailDetails.vue")
    },
    {
      path: "/hardfail/:id",
      name: "hardfail",
      meta: {
        external: true
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "ner" */ "../views/HardFail.vue")
    },
    {
      path: "*",
      name: "notFound",
      props: (route) => ({ redirect: route.query.redirect }),
      component: () =>
        import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue")
    },
    {
      path: "/AdminPanel",
      name: "AdminPanel",
      component: () =>
        import(/* webpackChunkName: "AdminPanel" */ "@/views/AdminPanel.vue"),
      props: true
    },
    {
      path: "/AdminPanelUsers",
      name: "AdminPanelUsers",
      component: () =>
        import(
          /* webpackChunkName: "AdminPanelUsers" */ "@/views/AdminPanelUsers.vue"
        ),
      props: true
    },
    {
      path: "/UsersManagement",
      name: "UsersManagement",
      component: () =>
        import(
          /* webpackChunkName: "AdminPanel" */ "@/views/UsersManagement.vue"
        ),
      props: true
    },
    {
      path: "/hardfailglt/:id",
      name: "hardfailglt",
      meta: {
        external: true
      },
      component: () =>
        import(/* webpackChunkName: "ner" */ "../views/HardFailGLT.vue"),
      props: true
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // Verify authentication
  const auth = await isAuth();
  if (to.matched.some((record) => record.meta.requiresAuth) && !auth) {
    next({ name: "login", params: { noAuth: true } });
  } else {
    next();
  }
});

export default router;
