"use strict";

import Vue from "vue";
import Vuex from "vuex";
import { updateEmailList } from "@/store/plugins";
import user from "@/store/user";
import emailList from "@/store/emailList";
import emailDetails from "@/store/emailDetails";
import adminPanelGeneral from "@/store/adminPanelGeneral";
import userHistory from "@/store/userHistory";
import usersManagement from "@/store/usersManagement";
import quote from "@/store/modules/quote";
import incompleteHardfailGlt from "../store/incompleteHardfailGlt";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    status: 0
  },
  modules: {
    user,
    emailList,
    emailDetails,
    adminPanelGeneral,
    userHistory,
    usersManagement,
    quote,
    incompleteHardfailGlt
  },
  plugins: [updateEmailList],
  mutations: {
    setStatus: (state, status) => {
      state.status = status;
    }
  }
});

export default store;
