"use strict";

import axios from "axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    infoGeneral: null,
    attachmentsHardFail: []
  },
  getters: {},
  actions: {
    async sendQuoteIncomplete(__, info) {
      let body = info.body;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/incomplete/${info.customId}/send-email`,
          body,
          { headers: headers }
        );
        if (response.status === 204) {
          Swal.fire({
            title: "Great!",
            text: "The information will be validated",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          });
          return response;
        }
        return response;
      } catch (err) {
        if (err.response.status === 422) {
          Swal.fire({
            title: "Error",
            text: err.response.data.detail[0].msg,
            icon: "error",
            showConfirmButton: true
          });
        } else if (err.response.status === 400) {
          Swal.fire({
            title: "Error",
            text: err.response.data.detail.detail,
            icon: "error",
            showConfirmButton: true
          });
        } else if (err.response.status !== 200) {
          Swal.fire({
            title: "There is something wrong",
            text: "Please, try again.",
            icon: "error"
          });
        }
        return err.response;
      }
    },
    async getHardfailGlt({ commit }, custom_id) {
      let params = {};
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/incomplete/${custom_id}/quotation`,
          {
            headers: headers,
            params: params
          }
        );
        if (response.status === 200) {
          commit ("setAttachments", response.data);
          return response;
        }
        return response;
      } catch (err) {
        if (err.response.status === 422) {
          Swal.fire({
            title: "Error",
            text: err.response.data.detail.detail,
            icon: "error",
            showConfirmButton: true
          });
        } else if (err.response.status === 404) {
          Swal.fire({
            title: "Error",
            text: err.response.data.detail.detail,
            icon: "error",
            showConfirmButton: true
          });
        } else if (err.response.status !== 200) {
          Swal.fire({
            title: "There is something wrong",
            text: "Please, try again.",
            icon: "error"
          });
        }
        return err.response;
      }
    },
    async updateHardfailGlt(__, info) {
      let body = {};
      body.custom_id = info.custom_id;
      body.hauls = info.haul;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json"
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL + `/api/v1/incomplete/update-entities/`,
          body,
          { headers: headers }
        );
        if (response.status === 204) {
          Swal.fire({
            title: "Great!",
            text: "The information will be validated",
            icon: "success"
          });
          return response;
        }
        return response;
      } catch (err) {
        if (err.response.status === 422) {
          Swal.fire({
            title: "Error",
            text: err.response.data.detail.detail,
            icon: "error",
            showConfirmButton: true
          });
        } else if (err.response.status === 405) {
          Swal.fire({
            title: "Error",
            text: err.response.data.detail,
            icon: "error",
            showConfirmButton: true
          });
        } else if (err.response.status !== 200) {
          Swal.fire({
            title: "There is something wrong",
            text: err.response.data.detail.detail,
            icon: "error"
          });
        }
        return err.response;
      }
    }
  },
  mutations: {
    setAttachments(state, newAttachment ) {
      state.attachmentsHardFail = newAttachment.attachments;
    }
  }
};
