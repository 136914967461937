"use strict";
import axios from "axios";
import router from "../router/index";
export default {
  namespaced: true,
  state: {
    auth: false,
    userStatus: "active",
    userStatusColor: "green",
    rolUser: null
  },
  getters: {
    userStatusColor: (state) => {
      return state.userStatusColor;
    },
    showRolUser(state) {
      return state.rolUser;
    },
    userStatus(state) {
      return state.userStatus;
    }
  },
  actions: {
    async updateUserStatus(_, data) {
      let body = {};
      body.is_busy = data.is_busy;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BASE_URL + `/api/v1/users/${data.id}`,
          body,
          { headers: headers }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    },
    loginUser: ({ commit }) => {
      commit("loginUser");
      // this must come from backend
      commit("setUserStatus", "active");
    },
    logoutUser: ({ commit }) => {
      commit("logoutUser");
      commit("setUserStatus", "logged out");
    }
  },
  mutations: {
    loginUser: (state) => (state.auth = true),
    logoutUser: (state) => (state.auth = false),
    setUserStatus: (state, userStatus) => {
      state.userStatus = userStatus;
      switch (userStatus) {
        case "active":
          state.userStatusColor = "#9FEC7B";
          break;
        case "busy":
          state.userStatusColor = "#EC5A5A";
          break;
        default:
          state.userStatusColor = "transparent";
          break;
      }
    },
    tokenValidation(state) {
      let token = localStorage.getItem("token");
      if (token) {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function(character) {
              return (
                "%" + ("00" + character.charCodeAt(0).toString(16)).slice(-2)
              );
            })
            .join("")
        );
        let infoToken = JSON.parse(jsonPayload);
        state.rolUser = infoToken;
        let dateNow = new Date();
        let expirationDate = new Date(infoToken.exp * 1000);
        if (dateNow.getTime() > expirationDate.getTime()) {
          localStorage.removeItem("token");
          router.push({
            name: "login"
          });
        }
      } else {
        router.push({
          name: "login"
        });
      }
    }
  }
};
