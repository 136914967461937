"use strict";

// import { v4 as uuidv4 } from "uuid";
import {
  getEmailList,
  deleteEmails,
  assignEmails
  // mapEmails
} from "@/api/services";

export default {
  namespaced: true,
  state: {
    emails: []
  },
  getters: {
    emails: (state) => state.emails
  },
  actions: {
    getEmails: async (
      { commit },
      { state, skip, limit, searchValue = null }
    ) => {
      return getEmailList(state, skip, limit, searchValue).then(
        async ({ data }) => {
          // const fullEmails = await mapEmails(data);
          // const processedEmails = await dispatch("preprocessEmails", data);
          commit("setEmails", data);
        }
      );
    },
    sortEmails: ({ commit }, column, dir) => {
      commit("sortEmails", column, dir);
    },
    // formats date, adds id an linear feet to trips
    preprocessEmails: (context, emails) => {
      emails.forEach((email) => {
        email.created_at = new Date(email.created_at).toLocaleString();
        email.haul.forEach((trip) => {
          trip.quote_id = email.custom_id;
        });
      });
      return emails;
    },
    deleteEmails: async ({ state, commit }, emailIds) => {
      return deleteEmails(emailIds).then(() => {
        const newEmails = state.emails.filter(
          (email) => !emailIds.includes(email.custom_id)
        );
        commit("setEmails", newEmails);
      });
    },
    assignEmails: async ({ commit, dispatch }, limit) => {
      const { data } = await assignEmails(limit);
      const processedEmails = await dispatch("preprocessEmails", data);
      commit("assignEmails", processedEmails);
    }
  },
  mutations: {
    setEmails: (state, emails) => {
      state.emails = emails;
    },
    assignEmails: (state, emails) => {
      state.emails = emails;
    },
    sortEmails: (state, column, dir) => {
      state.emails.sort((a, b) => {
        if (dir === "asc") return a[column] > b[column] ? 1 : -1;
        else return a[column] < b[column] ? 1 : -1;
      });
    }
  }
};
