"use strict";

// hyperCubes
export default {
  tmsLink: "https://teknowlogi.com/",
  tmsLoginLogo: "tai-logo.png",
  tmsLogo: "tai-logo.png",
  tmsName: "Teknowlogi",
  tmsLoginMessage: "Cargo Email Assistant",
  tmsRequiredEntities: [
    "hu_count",
    "zip_to",
    "city_to",
    "zip_from",
    "city_from",
    "weight"
  ],
  tmsFontColor: "#1f282d"
};

// TEKNOWLOGI
// export default {
//   tmsLink: "https://teknowlogi.com/",
//   tmsLoginLogo: "tai-logo.png",
//   tmsLogo: "tai-logo.png",
//   tmsName: "Teknowlogi",
//   tmsLoginMessage: "TAI Email Assistant",
//   tmsRequiredEntities: [
//     "hu_count",
//     "zip_to",
//     "zip_from",
//     "dimensions",
//     "weight"
//   ],
//   tmsFontColor: "#1f282d"
// };

// GLT
// export default {
//   tmsLink: "https://shipwithglt.com",
//   tmsLoginLogo: "logo-glt.png",
//   tmsLogo: "logo-glt.png",
//   tmsName: "Teknowlogi",
//   tmsLoginMessage: "GLT Email Assistant",
//   tmsRequiredEntities: [
//     "hu_count",
//     "zip_to",
//     "zip_from",
//     "dimensions",
//     "weight"
//   ],
//   tmsFontColor: "#811c0e"
// };
