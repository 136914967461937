<template>
  <div class="status-dropdown">
    <span
      ><div
        :style="{ backgroundColor: userStatusColor }"
        class="status-circle"
      ></div>
      status
    </span>
    <div class="dropdown-content">
      <ul class="status-list">
        <li class="dropdown-item" @click="setStatus($event.target)">Active</li>
        <li class="dropdown-item" @click="setStatus($event.target)">Busy</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "StatusDropdown",
  computed: {
    ...mapState("user", ["userStatus"]),
    ...mapGetters("user", ["userStatusColor"])
  },
  methods: {
    ...mapMutations("user", ["setUserStatus"]),
    async setStatus(target) {
      const status = target.textContent.trim().toLowerCase();
      this.setUserStatus(status);
      let userStatus = this.$store.getters["user/userStatus"];
      let userId = this.$store.getters["user/showRolUser"];
      let body = {};
      body.id = userId.user_id;
      if (userStatus === "active") {
        body.is_busy = false;
      } else {
        body.is_busy = true;
        this.$router.push({ name: "home" });
      }
      await this.$store.dispatch("user/updateUserStatus", body);
    }
  },
  async created() {
    let userId = this.$store.getters["user/showRolUser"];
    if (userId) {
      let body = {};
      body.is_busy = false;
      body.id = userId.user_id;
      await this.$store.dispatch("user/updateUserStatus", body);
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.status-dropdown {
  position: relative;

  span {
    height: 100% !important;
  }

  .status-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 5px;
  }

  &:hover .dropdown-content {
    display: block;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: var(--sidebar-bg-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  z-index: 1;
  color: #a3a3a3;

  .status-list {
    list-style-position: inside;
    list-style-type: none;
    text-align: justify;
  }

  .dropdown-item {
    color: #a3a3a3;

    &:hover {
      background-color: var(--app-font-color);
      color: #ffffff;
    }
  }
}
</style>
