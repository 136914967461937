<template>
  <header class="sidebar">
    <!-- <LogoPanel :tmsLogo="tmsLoginLogo" height="110px" :link="true" /> -->
    <nav class="nav">
      <SidebarLink class="menu-link" to="/">
        <font-awesome-icon class="link-icon" icon="home" />
        <label class="link-label">Home</label>
      </SidebarLink>
      <SidebarLink v-if="rolUser == 'admin'" class="menu-link" to="/AdminPanel">
        <font-awesome-icon class="link-icon" icon="users-cog" />
        <label class="link-label">Admin</label>
      </SidebarLink>
      <SidebarLink class="menu-link" to="/emails/ner" type="ner">
        <font-awesome-icon class="link-icon" icon="list-ul" />
        <label class="link-label">Email Quoting</label>
      </SidebarLink>
      <SidebarLink
        v-if="rolUser == 'admin'"
        class="menu-link"
        to="/emails/failed"
        type="failed"
      >
        <font-awesome-icon class="link-icon" icon="times-circle" />
        <label class="link-label">Failed LTL</label>
      </SidebarLink>
      <SidebarLink class="menu-link" to="/emails/sent" type="sent">
        <font-awesome-icon class="link-icon" icon="paper-plane" />
        <label class="link-label">Sent LTL</label>
      </SidebarLink>
      <SidebarLink class="menu-link" to="/emails/nonLtl" type="nonLtl">
        <font-awesome-icon class="link-icon" icon="exclamation-triangle" />
        <label class="link-label">Non LTL</label>
      </SidebarLink>
      <SidebarLink class="menu-link" to="/emails/hardfail" type="hardfail">
        <font-awesome-icon class="link-icon" icon="exclamation-circle" />
        <label class="link-label">Hard Fails</label>
      </SidebarLink>
      <SidebarLink
        class="menu-link"
        to="/emails/webhookFails"
        type="webhookFails"
      >
        <font-awesome-icon class="link-icon" icon="times" />
        <label class="link-label">Webhook Fails</label>
      </SidebarLink>
    </nav>
  </header>
</template>

<script>
import tmsConfig from "@/utils/tmsConfig";
/* import LogoPanel from "@/components/LogoPanel"; */
import SidebarLink from "@/components/SidebarLink";

export default {
  name: "TheSidebar",
  components: {
    /* LogoPanel, */
    SidebarLink
  },
  data: function() {
    return {
      tmsLink: tmsConfig.tmsLink,
      tmsLogo: tmsConfig.tmsLogo,
      tmsLoginLogo: tmsConfig.tmsLoginLogo,
      rolUser: null
    };
  },
  methods: {
    isHardFail() {
      if (
        this.$router.history.pending.name === "hardfail" ||
        this.$router.history.pending.name === "hardfailglt"
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  async created() {
    if (!this.isHardFail()) {
      this.$store.commit("user/tokenValidation");
    }
    let rolUser = this.$store.getters["user/showRolUser"];
    rolUser != null ? (this.rolUser = rolUser.mode) : "";
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logo-collapsed {
  visibility: hidden;
}

.logo-expanded {
  visibility: visible;
}

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 60px;
  background-color: var(--sidebar-bg-color);
  text-align: justify;
  color: var(--app-font-color);
  border-right: 0.5px solid #a3a3a3;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.5s;
  z-index: 5;

  .nav {
    text-decoration: none;
    height: auto;
    padding-top: 20px;
  }

  .logo-wrapper {
    // background-color: var(--sidebar-logo-bg-color);
    display: block;
    transition: visibility 0.3s;
    border-right: 1px solid #dcd7d6;
    visibility: hidden;
    padding: 20px 18px;

    img.tms-logo {
      width: 100%;
      padding: 10px;
      margin: 30px auto;
      display: block;
      border-radius: 3px;
    }
  }

  &:hover {
    width: 220px;
  }

  &:hover .logo-wrapper {
    visibility: visible;
  }
}

.menu-link {
  color: var(--app-font-color);
  display: inline-block;
  font-size: 16px;
  text-decoration: none;
  width: 100%;
  transition: font-size 0.1s;

  &:hover {
    background-color: var(--title-color);
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
  }

  .link-icon {
    font-size: 22px;
    vertical-align: middle;
    padding-bottom: 3px;
    width: 60px;
  }

  .link-label {
    display: inline-block;
    width: 100%;
    padding: 20px 0 10px 20px;
    cursor: pointer;
  }
}
</style>
