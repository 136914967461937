"use strict";

import Vue from "vue";
import App from "./App.vue";

// Vuex Store
import store from "./store";

// Vue Router
import router from "./router";

// BootstrapVue framework
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Fontawesome Framework
//fontAwesome
import "@/plugins/fontAwesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faTrash,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faListUl,
  faSpinner,
  faTimesCircle,
  faPaperPlane,
  faExclamation,
  faPlus,
  faMinus,
  faSearch,
  faArrowLeft,
  faExclamationTriangle,
  faTimes,
  faEye,
  faUnlock,
  faUserLock,
  faFilter,
  faSync,
  faUsersCog,
  faSignOutAlt,
  faExclamationCircle,
  faMapMarkerAlt,
  faUserEdit
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faUserEdit,
  faHome,
  faTrash,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faListUl,
  faSpinner,
  faTimesCircle,
  faPaperPlane,
  faExclamation,
  faExclamationTriangle,
  faTimes,
  faPlus,
  faMinus,
  faSearch,
  faArrowLeft,
  faEye,
  faUnlock,
  faUserLock,
  faFilter,
  faSync,
  faUsersCog,
  faSignOutAlt,
  faExclamationCircle,
  faMapMarkerAlt
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Vue Filters
import "./filters/filters.js";

// PrimeVue
import "./plugins/primeVue";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
