"use strict";

import axios from "axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    infoGeneral: null
  },
  getters: {},
  actions: {
    async getInfoGeneral({ commit }, data) {
      let params = {};
      params.initial_date = data.initial_date;
      params.final_date = data.final_date;
      params.response_type = data.response_type;
      params.username = data.username;
      params.organization_id =
        data.organization_id != "" ? data.organization_id : null;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/statistics/quotes-per-user${data.status}`,
          {
            headers: headers,
            params: params
          }
        );
        if (response.status == 200) {
          commit("setInfoGeneral", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          console.log(err);
        }
      }
    },
    async getReportQuotesPerInterval({ commit }, data) {
      let params = {};
      params.initial_date = data.initial_date;
      params.final_date = data.final_date;
      params.response_type = data.response_type;
      params.username = data.username;
      params.organization_id =
        data.organization_id != "" ? data.organization_id : null;
      params.minutes_interval = data.minutesInterval;
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/statistics/users/quotes-per-interval${data.status}`,
          {
            headers: headers,
            params: params
          }
        );
        if (response.status == 200) {
          commit("setInfoGeneral", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          console.log(err);
        } else if (err.response.status == 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `No data found in the specified interval`
          })
        }
      }
    },
    async getReportByClient(_, info) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        if (info.initial_date && info.final_date) {
          let response = await axios.get(
            process.env.VUE_APP_BASE_URL +
              `/api/v1/quotes/report-for-client?client_id=${info.client_id}&initial_date=${info.initial_date}T00:00:00&final_date=${info.final_date}T23:59:59.999999`,
            {
              headers: headers
            }
          );
          return response.data;
        } else {
          let response = await axios.get(
            process.env.VUE_APP_BASE_URL +
              `/api/v1/quotes/report-for-client?client_id=${info.client_id}`,
            {
              headers: headers
            }
          );
          return response.data;
        }
      } catch (err) {
        if (err.response.status == 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Validation error`
          });
        } else if (err.response.status == 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Client not found`
          });
        }
      }
    },
    async getReportByAutoquote(_, info) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        if (info.initial_date && info.final_date) {
          let response = await axios.get(
            process.env.VUE_APP_BASE_URL +
              `/api/v1/quotes/report-for-autoquote?initial_date=${info.initial_date}T00:00:00&final_date=${info.final_date}T23:59:59.999999`,
            {
              headers: headers
            }
          );
          return response.data;
        } else {
          let response = await axios.get(
            process.env.VUE_APP_BASE_URL +
              `/api/v1/quotes/report-for-autoquote`,
            {
              headers: headers
            }
          );
          return response.data;
        }
      } catch (err) {
        if (err.response.status == 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Validation error`
          });
        }
      }
    },
    async getReportByAvgTimePerQuote(_, info) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL +
            `/api/v1/quotes/report-avg-time-per-quote?custom_id=${info.custom_id}`,
          {
            headers: headers
          }
        );
        return response.data;
      } catch (err) {
        if (err.response.status == 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Validation error`
          });
        }
      }
    },
    async getReportGeneralByClient(_, info) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        if (info.initial_date && info.final_date) {
          let response = await axios.get(
            process.env.VUE_APP_BASE_URL +
              `/api/v1/quotes/report-general-for-client?initial_date=${info.initial_date}T00:00:00&final_date=${info.final_date}T23:59:59.999999`,
            {
              headers: headers
            }
          );
          return response.data;
        } else {
          let response = await axios.get(
            process.env.VUE_APP_BASE_URL +
              `/api/v1/quotes/report-general-for-client`,
            {
              headers: headers
            }
          );
          return response.data;
        }
      } catch (err) {
        if (err.response.status == 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Validation error`
          });
        }
      }
    }
  },
  mutations: {
    setInfoGeneral(state, generalInformation) {
      state.infoGeneral = generalInformation;
    }
  }
};
