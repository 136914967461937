"use strict";

import API from "@/api/methods";

export const getStandardizedEntity = (entity, entityValue) => {
  const token = localStorage.getItem("token");
  const resource = `/operations/standardize_${entity}/`;
  const payload = { [entity]: entityValue };
  return API.post(resource, payload, token);
};

export const getNMFC = (row) => {
  const token = localStorage.getItem("token");
  const resource = "/operations/nmfc_calculator/";
  const payload = {
    hu_count: row.hu_count,
    dimensions: row.dimensions,
    weight: row.weight
  };
  return API.post(resource, payload, token);
};

export const getTotalWeight = (weight) => {
  const token = localStorage.getItem("token");
  const resource = "/operations/get_total_weight/";
  const payload = { weight };
  return API.post(resource, payload, token);
};

export const getTotalVolume = (dimensions, huCount) => {
  const token = localStorage.getItem("token");
  const resource = "/operations/get_total_volume/";
  const payload = { dimensions, hu_count: huCount };
  return API.post(resource, payload, token);
};

export const getTotalHuCount = (huCount) => {
  const token = localStorage.getItem("token");
  const resource = "/operations/get_total_hu_count/";
  const payload = { hu_count: huCount };
  return API.post(resource, payload, token);
};

export const getContributions = (dimensions, huCount, totalVolume) => {
  const token = localStorage.getItem("token");
  const resource = "/operations/get_contributions/";
  const payload = {
    dimensions,
    hu_count: huCount,
    total_volume: totalVolume
  };
  return API.post(resource, payload, token);
};

export const getWeightsFromContributions = (totalWeight, contributions) => {
  const token = localStorage.getItem("token");
  const resource = "/operations/get_weights_from_contributions/";
  const payload = {
    contributions,
    total_weight: totalWeight
  };
  return API.post(resource, payload, token);
};

export const countEmails = (status) => {
  const token = localStorage.getItem("token");
  const resource = `/quotes/count?status=${status}`;
  return API.get(resource, token);
};

export const getEmailList = (status, skip, limit, searchValue) => {
  const token = localStorage.getItem("token");
  let resource = `/quotes?skip=${skip}&limit=${limit}&status=${status}`;
  if (searchValue !== null) resource = resource.concat(`&q=${searchValue}`);
  return API.get(resource, token);
};

export const mapEmails = (emails) => {
  return new Promise(function(resolve, reject) {
    try {
      const token = localStorage.getItem("token");
      const promises = [];
      emails.forEach((email) => {
        const resource = `/quotes/${email.custom_id}/all-info`;
        promises.push(API.get(resource, token));
      });
      Promise.all(promises).then((responses) => {
        const emails = responses.map((response) => response.data);
        resolve(emails);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const assignEmails = (limit) => {
  const token = localStorage.getItem("token");
  const resource = `/quotes/assign?limit=${limit}`;
  return API.patch(resource, {}, token);
};

export const getEmailById = (custom_id) => {
  const token = localStorage.getItem("token");
  let resource = `/quotes/${custom_id}`;
  return API.get(resource, token);
};

export const getHardFailById = (custom_id) => {
  const resource = `/quotes/hardfail/${custom_id}`;
  return API.get(resource);
};

export const sendHardFail = (payload) => {
  const id = payload.custom_id;
  const resource = `/quotes/hardfail/send/${id}`;
  return API.patch(resource, payload);
};

export const deleteEmails = (emails) => {
  return new Promise((resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const promises = [];
      emails.forEach((custom_id) => {
        const resource = `/quotes/${custom_id}`;
        promises.push(API.delete(resource, token));
      });
      Promise.all(promises).then(() => resolve());
    } catch (err) {
      reject(err);
    }
  });
};

export const patchEmail = (custom_id, payload) => {
  const token = localStorage.getItem("token");
  const resource = `/quotes/${custom_id}`;
  return API.patch(resource, payload, token);
};

export const sendQuote = (customId, payload) => {
  const token = localStorage.getItem("token");
  const resource = `/quotes/${customId}/send`;
  return API.patch(resource, payload, token);
};

export const addTrip = (customId) => {
  const token = localStorage.getItem("token");
  const resource = `/hauls`;
  const payload = {
    quote_id: customId,
    pickup_request: {},
    delivery_request: {}
  };
  return API.post(resource, payload, token);
};

export const deleteTrip = (haulId) => {
  const token = localStorage.getItem("token");
  const resource = `/hauls/${haulId}`;
  return API.delete(resource, token);
};

export const addCommodity = (haulId) => {
  const token = localStorage.getItem("token");
  const resource = `/commodities`;
  return API.post(resource, { haul_id: haulId }, token);
};

export const deleteCommodity = (commodityId) => {
  const token = localStorage.getItem("token");
  const resource = `/commodities/${commodityId}`;
  return API.delete(resource, token);
};
