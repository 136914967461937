<template>
  <footer class="footer">
    <p>
      Copyright &copy; {{ year }} | developed by
      <a href="http://hypercubes.io/" target="_blank">hyper</a>cubes
      <br />
      Contact information
      <a href="mailto://charlie@guane.com.co"> charlie@guane.com.co</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data: function() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #666666;
  border-top: 0.5px solid #a3a3a3;
  width: 100%;
  height: 85px;
  z-index: 3;
  a {
    color: #04ccfc;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>
