"use strict";

import API from "@/api/methods";

const getAuth = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    token !== null ? resolve(true) : reject(false);
  });
};

const isAuth = async () => {
  try {
    let res = await getAuth();
    return res;
  } catch (err) {
    return false;
  }
};

const login = (formData) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    API.post("/login/access-token", formData, token)
      .then((res) => {
        const token = res.data.access_token;
        localStorage.setItem("token", token);
        resolve();
      })
      .catch((err) => reject(err));
  });
};

const logout = () => {
  localStorage.removeItem("token");
};

export { isAuth, login, logout };
