<template>
  <nav class="the-navbar">
    <div class="user-section">
      <StatusDropdown class="menu-link" />
      <li class="menu-link" @click="logout">
        <font-awesome-icon class="link-icon" icon="sign-out-alt" />Logout
      </li>
    </div>
  </nav>
</template>

<script>
import * as auth from "@/middlewares/auth";
/* import SidebarLink from "@/components/SidebarLink"; */
import StatusDropdown from "@/components/StatusDropdown";
import { mapMutations } from "vuex";

export default {
  name: "TheNavbar",
  components: {
    /* SidebarLink, */
    StatusDropdown
  },
  computed: {
    searchInput: function() {
      return this.$el.querySelector(".searchbar");
    }
  },
  methods: {
    ...mapMutations("user", ["logoutUser", "setUserStatus"]),
    logout: function() {
      auth.logout();
      this.logoutUser();
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.the-navbar {
  height: 40px;
  background-color: var(--sidebar-bg-color);
  color: #a3a3a3;
  border-bottom: 0.5px solid #a3a3a3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  transition: font-size 100ms;
  transition: width 0.5s;
  z-index: 5;

  .user-section {
    display: flex;
  }

  .menu-link {
    padding: 8px 20px;
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: var(--title-color);
      color: #ffffff;
    }
  }
}
</style>
