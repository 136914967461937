"use strict";

import axios from "axios";
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    userHistory: null
  },
  getters: {},
  actions: {
    async getUserHistory({ commit }, params) {
      const token = localStorage.getItem("token");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/api/v1/user-history`,
          {
            headers: headers,
            params: params
          }
        );
        if (response.status === 200) {
          commit("setUserHistory", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Not Found`
          });
        } else if (err.response.status === 422) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Validation Error`
          });
        }
      }
    }
  },
  mutations: {
    setUserHistory(state, usersHistoryInformation) {
      state.userHistory = usersHistoryInformation;
    }
  }
};
